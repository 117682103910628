import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalBody, ModalHeader, Col } from 'reactstrap';

const ContentModal = ({ children, title, show }) => {
    return (
        <Modal isOpen={show}>
            <ModalHeader className="text-center justify-content-center">
                {title}
            </ModalHeader>
            <ModalBody>
                <Col>
                    {children}
                </Col>
            </ModalBody>
        </Modal>
    )
}


ContentModal.propTypes = {
    title: PropTypes.string.isRequired
}

export default ContentModal;
