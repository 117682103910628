/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Card, CardHeader, CardBody, Container, Col } from 'reactstrap';
import Header from 'components/Headers/Header.js';

const Documentation = () => {
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className=" mt--7" fluid>
                <Col className="h-100">
                    <Card>
                        <CardHeader>
                            <h3 className=" mb-0">Documentation</h3>
                        </CardHeader>
                        <CardBody className="h-100">
                            <iframe title="documentation" className="w-100 rounded" height="1000" src="https://production.api-annuaire-sante.fr/" />
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </>
    );
};

export default Documentation;
