import React from 'react';
import PropTypes from 'prop-types';

const InvoicesHeader = ({ fields }) => {
    return (
        <thead>
            <tr>
                {fields.map(field => {
                    return <th key={field} scope="col">{field}</th>
                })}
            </tr>
        </thead>
    )
}

InvoicesHeader.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default InvoicesHeader;