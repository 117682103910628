import { createContext } from "react";

const AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: (value) => {},
    user: {},
    setUser: (value) => {},
    isLoading: false,
    setIsLoading: (value) => {},
    initialLoading: false,
    setInitialLoading: (value) => {},
    stripeUser: null,
    setStripeUser: (value) => {},
});

export default AuthContext;
