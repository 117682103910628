import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useError from 'hooks/useError';

import InformationModal from 'components/Modal/InformationModal';
import LoadingModal from 'components/Modal/LoadingModal';

import StripeService from 'services/StripeService';
import UserService from 'services/UserService';
import AuthContext from 'contexts/AuthContext';

const SubscriptionSuccess = ({ match }) => {
    const context = useContext(AuthContext);
    const [hasError, errorMessage, setError] = useError();
    const history = useHistory();
    const stripeService = new StripeService();
    const userService = new UserService();

    useEffect(() => {
        const updateRole = async () => {
            const sessionId = match.params.sessionId;

            context.setIsLoading(true);
            try {
                const result = await stripeService.newSubscription(context.user.email, sessionId, context.user.stripeId);

                if (result.status === 200) {
                    const newUserInfo = await userService.getUser();
                    const subscription = await stripeService.getSubscription(newUserInfo.stripeId);
                    context.setUser(newUserInfo);
                    context.setStripeSubscription(subscription);
                } else {
                    setError(true, result.data.error);
                }
            } catch (e) {
                setError(true, e.message);
            }
            context.setIsLoading(false);
        }
        updateRole();
    }, []);


    return (
        <>
            {context.isLoading ? (
                <LoadingModal isOpen={true} />
            ) : (
                <InformationModal isOpen={true} information="Achat réalisé avec succès." color="success" onContinue={() => history.replace('/admin/mon-abonnement')} />
            )}
        </>
    )
}

export default SubscriptionSuccess;