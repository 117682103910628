import React, { useContext, useState } from 'react';

import AuthContext from 'contexts/AuthContext';
import pushSlack from 'services/functions/webhooks';
import { SLACK_FULL } from 'config';

import { Input, Text, Button, Spinner, Row } from 'reactstrap';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const ContactForm = ({ onSubmit, onClose }) => {
    const context = useContext(AuthContext);
    const user = context.user;

    const [contactData, setContactData] = useState({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        message: ''
    });

    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setContactData({ ...contactData, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        context.setIsLoading(true);
        pushSlack(SLACK_FULL, { 
            type: 'TEXT',
            content: [`Contact de ${context.user.firstName} ${context.user.lastName} (${context.user.email}): \n${contactData.message}`]
        });
        context.setIsLoading(false);
        onSubmit()
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)} name="contact">
            <div className="columns is-multiline">
                <div className="column is-12">
                    <Input className="input is-medium m-2" type="email" value={user.email} disabled placeholder="Email *" name="email-contact" />
                </div>
                <div className="column is-12">
                    <Input className="input is-medium m-2" type="text" value={user.firstName} disabled placeholder="Prénom *" name="firstname-contact" />
                </div>
                <div className="column is-12">
                    <Input className="input is-medium m-2" type="text" value={user.lastName} disabled placeholder="Nom *"  name="lastname-contact" />
                </div>
                <div className="column is-12">
                    <Input onChange={(e) => handleChange(e)} type="textarea" className="textarea m-2" rows="6" placeholder="Votre message"  name="message" />
                </div>
                <div className="column is-12">
                    <Row className="justify-content-center">
                        <Button onClick={onClose}>Fermer</Button>
                        <Button type="submit" color="primary">{context.isLoading ? <Spinner size="sm" /> : 'Envoyer'}</Button>
                    </Row>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;