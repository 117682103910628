/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import AuthContext from 'contexts/AuthContext';
import { Link } from 'react-router-dom';

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
	static contextType = AuthContext;
	
	getPlan() {
		const role = this.context.user.roles ? this.context.user.roles[0] : 'DEFAULT';
		switch (role) {
			case 'ROLE_USER': return 'Starter (Gratuit)';
			case 'ROLE_PRO': return 'Pro';
			case 'ROLE_BUSINESS': return 'Business';
			case 'ROLE_ADMIN': return 'Administrateur';
			default: return 'Mon plan';
		}
	}

	getIcon() {
		if (this.context.user.maxRequest <= this.context.user.nhitsToday) {
			return 'fa fa-times';
		}
		return 'fa fa-check';
	}

	getColor() {
		if (this.context.user.maxRequest <= this.context.user.nhitsToday) {
			return 'text-danger';
		}
		return 'text-success';
	}

	getRemainingRequest() {
		const role = this.context.user.roles ? this.context.user.roles[0] : 'DEFAULT';
		if (this.context.user.maxRequest === Infinity) {
			return 'Illimité';
		}
		return String(this.context.user.maxRequest - this.context.user.nhitsToday);
	}

	getRemainingRequestText() {
		const role = this.context.user.roles ? this.context.user.roles[0] : 'DEFAULT';
		if (this.context.user.maxRequest === Infinity) {
			return '';
		}
		return 'Requêtes restantes';
	}

	render() {
		return (
			<>
				<div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'>
					<Container fluid>
						<div className='header-body'>
							{/* Card stats */}
							<Row>
								<Col lg='4' xl='4'>
									<Card className='card-stats mb-4 mb-xl-0 h-100'>
										<CardBody>
											<Row>
												<div className='col'>
													<CardTitle
														tag='h5'
														className='text-uppercase text-muted mb-0'>
														Nombre de requetes aujourd'hui
													</CardTitle>
													<span className='h2 font-weight-bold mb-0'>{this.context.user.nhitsToday}</span>
												</div>
												<Col className='col-auto'>
													<div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
														<i className='fas fa-chart-bar' />
													</div>
												</Col>
											</Row>
											<p className='mt-3 mb-0 text-muted text-sm'>
												<span className={`${this.getColor()} mr-2`}>
													<i className={this.getIcon()} />{" "}
													<span className="font-weight-bold">{this.getRemainingRequest()}</span>
												</span>
												<span className='text-nowrap'>{this.getRemainingRequestText()}</span>
											</p>
										</CardBody>
									</Card>
								</Col>
								<Col lg='4' xl='4'>
									<Card className='card-stats mb-4 mb-xl-0 h-100'>
										<CardBody>
											<Row>
												<div className='col'>
													<CardTitle
														tag='h5'
														className='text-uppercase text-muted mb-0'>
														Nombre de requetes total
													</CardTitle>
													<span className='h2 font-weight-bold mb-0'>{this.context.user.nhitsTotal}</span>
												</div>
												<Col className='col-auto'>
													<div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
														<i className='fas fa-chart-pie' />
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
								<Col lg='4' xl='4'>
									<Card className='card-stats mb-4 mb-xl-0 h-100'>
										<CardBody>
											<Row>
												<div className='col'>
													<CardTitle
														tag='h5'
														className='text-uppercase text-muted mb-0'>
														Plan actif
													</CardTitle>
													<span className='h2 font-weight-bold mb-0'>
														{this.getPlan()}
													</span>
												</div>
												<Col className='col-auto'>
													<div className='icon icon-shape bg-yellow text-white rounded-circle shadow'>
														<i className='fas fa-users' />
													</div>
												</Col>
											</Row>
											<p className='mt-3 mb-0 text-muted text-sm'>
												<span className='text-warning mr-2'>
													<i className='fas fa-arrow-down' /> -20% sur
													l'abonnement
												</span>{" "}
												<span className='text-nowrap'>
													<Link to='/admin/mon-abonnement'>
														Cliquez ici pour changer de plan
													</Link>
												</span>
											</p>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</>
		);
	}
}

export default Header;
