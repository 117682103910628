/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment, useState, useContext, useEffect } from 'react';
import useError from 'hooks/useError';
import AuthContext from 'contexts/AuthContext';
import UserService from 'services/UserService';
import AuthService from 'services/AuthService';
import Validator, { isEmail, isRequired } from 'services/Validation';

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col, Spinner } from 'reactstrap';
import UserHeader from 'components/Headers/UserHeader.js';

const Profile = () => {
    const authService = new AuthService();
    const userService = new UserService();

    const [hasError, errorMessage, setError] = useError();
    const context = useContext(AuthContext);
    const [isModifying, setIsModifying] = useState(false);
    const [fields, setFields] = useState({
        email: {
            value: '',
            error: '',
            validations: [{ rule: isEmail }],
        },
        firstName: {
            value: '',
            error: '',
            validations: [{ rule: isRequired }],
        },
        lastName: {
            value: '',
            error: '',
            validations: [{ rule: isRequired }],
        },
        company: {
            value: '',
            error: '',
        },
        newPass: {
            value: '',
            error: '',
        },
        newPassConf: {
            value: '',
            error: '',
        },
    });

    const validator = new Validator(fields);

    const createNewUser = () => {
        const { company, newPass, email, firstName, lastName } = fields;
        const newUser = {
            email: email.value,
            firstName: firstName.value,
            lastName: lastName.value,
            company: company.value === '' ? null : company.value,
        };
        if (newPass.value !== '') {
            newUser.password = newPass.value;
        }

        return newUser;
    };

    const handleChange = (value, field) => {
        setFields({
            ...fields,
            [field]: {
                ...fields[field],
                value,
            },
        });
    };

    const setFieldErrors = (errors) => {
        const newFields = { ...fields };
        errors.forEach((error) => {
            newFields[error.field] = {
                ...newFields[error.field],
                error: error.message,
            };
        });
        setFields(newFields);
    };

    const setOrResetInputs = () => {
        // Set les inputs aux valeurs du context.
        setFields({
            email: { ...fields.email, value: context.user.email, error: '' },
            firstName: { ...fields.firstName, value: context.user.firstName, error: '' },
            lastName: { ...fields.lastName, value: context.user.lastName, error: '' },
            company: { ...fields.company, value: context.user.company, error: '' },
            newPass: { ...fields.newPass, value: '', error: '' },
            newPassConf: { ...fields.newPassConf, value: '', error: '' },
        });
    };

    const toggleIsModifying = () => {
        setIsModifying((prevIsModifying) => {
            return !prevIsModifying;
        });
    };

    const handleClickModify = async () => {
        if (!isModifying) {
            toggleIsModifying();
        } else {
            const errors = validator.validate();

            // Check for passwords
            if (fields.newPassConf.value !== fields.newPass.value) {
                errors.push({
                    field: 'newPass',
                    message: 'Les mots de passe ne correspondent pas.',
                });
            }

            if (errors.length) {
                setFieldErrors(errors);
            } else {
                await submitChanges();

                if (authService.isAuthenticated()) {
                    // Remove previous errors and remove password input values
                    setFields({
                        email: { ...fields.email, error: '' },
                        firstName: { ...fields.firstName, error: '' },
                        lastName: { ...fields.lastName, error: '' },
                        company: { ...fields.company, error: '' },
                        newPass: { ...fields.newPass, value: '', error: '' },
                        newPassConf: { ...fields.newPassConf, value: '', error: '' },
                    });
                    toggleIsModifying();
                }
            }
        }
    };

    const handleClickCancel = () => {
        // Si on est en train d'update l'utilisateur => impossible d'annuler l'action
        if (context.isLoading) {
            return;
        }

        setOrResetInputs();
        toggleIsModifying();
        setError(false);
    };

    const submitChanges = async () => {
        const newUser = createNewUser();
        const id = context.user['@id'];

        // Update les données utilisateurs
        context.setIsLoading(true);
        setError(false);
        try {
            const updatedUser = await userService.updateUser(newUser, id);
            if (updatedUser['@id'] && (fields.newPass.value !== '' || fields.email.value !== context.user.email)) {
                authService.logout();
                context.setIsAuthenticated(false);
            } else {
                setError(false);

                // Update the user in context (also update the UI)
                context.setUser({
                    ...context.user,
                    ...updatedUser,
                });
            }
        } catch (error) {
            setError(true, error.message);
        }
        context.setIsLoading(false);
    };

    const isInputDisabled = () => {
        return !isModifying || context.isLoading;
    };

    useEffect(() => {
        if (context.isLoading) {
            context.setIsLoading(false);
        }
        setOrResetInputs();
    }, []);

    return (
        <Fragment>
            <UserHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="justify-content-center">
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">
                                            Mon compte <span className="text-danger">{errorMessage}</span>{' '}
                                        </h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Row className="flex-row-reverse">
                                            <Button
                                                color={isModifying ? 'success' : 'primary'}
                                                onClick={() => handleClickModify()}
                                                className="m-1"
                                                size="sm"
                                            >
                                                {context.isLoading ? <Spinner size="sm" color="white" /> : isModifying ? 'Sauvegarder' : 'Modifier'}
                                            </Button>
                                            {isModifying && (
                                                <Button
                                                    color="danger"
                                                    disabled={context.isLoading}
                                                    onClick={() => {
                                                        handleClickCancel();
                                                    }}
                                                    className="m-1"
                                                    size="sm"
                                                >
                                                    Annuler
                                                </Button>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">Informations personnelles</h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-first-name">
                                                        Prénom <span className="text-danger">{fields.firstName.error}</span>
                                                    </label>
                                                    <Input
                                                        disabled={isInputDisabled()}
                                                        onChange={(e) => handleChange(e.currentTarget.value, 'firstName')}
                                                        className="form-control-alternative"
                                                        value={fields.firstName.value}
                                                        id="input-first-name"
                                                        placeholder="Prénom"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-last-name">
                                                        Nom <span className="text-danger">{fields.lastName.error}</span>
                                                    </label>
                                                    <Input
                                                        disabled={isInputDisabled()}
                                                        onChange={(e) => handleChange(e.currentTarget.value, 'lastName')}
                                                        className="form-control-alternative"
                                                        value={fields.lastName.value}
                                                        id="input-last-name"
                                                        placeholder="Nom"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-mail">
                                                        Email <span className="text-danger">{fields.email.error}</span>{' '}
                                                    </label>
                                                    <Input
                                                        disabled={true}
                                                        className="form-control-alternative"
                                                        value={fields.email.value}
                                                        id="input-mail"
                                                        placeholder="Email"
                                                        type="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Autres informations</h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-entreprise">
                                                        Entreprise
                                                    </label>
                                                    <Input
                                                        disabled={isInputDisabled()}
                                                        onChange={(e) => handleChange(e.currentTarget.value, 'company')}
                                                        className="form-control-alternative"
                                                        value={fields.company.value === null ? '' : fields.company.value}
                                                        id="input-entreprise"
                                                        placeholder="Aucune"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Changer de mot de passe <span className="text-danger">{fields.newPass.error}</span>{' '}
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-pass">
                                                        Nouveau mot de passe{' '}
                                                    </label>
                                                    <Input
                                                        disabled={isInputDisabled()}
                                                        onChange={(e) => handleChange(e.currentTarget.value, 'newPass')}
                                                        value={fields.newPass.value}
                                                        className="form-control-alternative"
                                                        id="input-pass"
                                                        placeholder="Nouveau mot de passe"
                                                        type="password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-pass-conf">
                                                        Confirmation mot de passe
                                                    </label>
                                                    <Input
                                                        disabled={isInputDisabled()}
                                                        onChange={(e) => handleChange(e.currentTarget.value, 'newPassConf')}
                                                        value={fields.newPassConf.value}
                                                        className="form-control-alternative"
                                                        id="input-pass-conf"
                                                        placeholder="Confirmation mot de passe"
                                                        type="password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Profile;
