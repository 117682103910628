import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import LoadingModal from 'components/Modal/LoadingModal';
import InformationModal from 'components/Modal/InformationModal';

//Stripe
import { loadStripe } from '@stripe/stripe-js';
import { PRODUCT_PLANS, STRIPE_PUBLISHABLE_KEY, ROLE_TO_PLAN } from './Const';
import { CurrencySymbol, SubscriptionPlansWrapper, SubscriptionPlanCard, SubscriptionPlanCardHeading, SubscriptionPlanCardPrice, SubscriptionPlanCardSubHeading, } from './Style';

import StripeService from 'services/StripeService';
import AuthContext from 'contexts/AuthContext';
import AuthService from 'services/AuthService';
import UserService from 'services/UserService';
import useError from 'hooks/useError';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const SubscribeToProduct = () => {
    const context = useContext(AuthContext);
    const [hasError, errorMessage, setError] = useError();

    const [unsubscribing, setUnsubscribing] = useState(false);
    const [changingPlan, setChangingPlan] = useState(false);
    const [confirmation, setConfirmation] = useState({
        show: false,
        text: ''
    });
    const [productId, setProcuctId] = useState(null);

    const stripeService = new StripeService();
    const userService = new UserService();
    const authService = new AuthService();

    useEffect(() => {
        if (context.isLoading) {
            context.setIsLoading(false);
        }
    }, []);

    const handleClick = async (product) => {
        if (context.user.stripeId === null || context.stripeSubscription === null) {
            subscribeToPlan(product);
        } else {
            const plan = ROLE_TO_PLAN[context.user.roles[0]];
            setProcuctId(product);
            if (plan === product) {
                setUnsubscribing(true);
            } else {
                setChangingPlan(true);
            }
        }
    };

    const tokenExpiresSoon = () => {
        const { exp } = authService.getTokenInfo();
        const time = Math.round((new Date().getTime()) / 1000);
        const remainingTimeBeforeExp = (exp - time) / 60;
        return remainingTimeBeforeExp < 10;
    }

    const logoutToRenewToken = () => {
        authService.setLoginInfo('Le token va bientôt expirer. Veuillez vous reconnecter.');
        authService.logout();
        context.setIsAuthenticated(false);
    }

    const action = async (action, product) => {
        let result = null;
        context.setIsLoading(true);
        try {
            if (action === 'change') {
                result = await stripeService.changeSubscription(context.user.email, product, context.stripeSubscription.id, context.user.stripeId);
            } else {
                result = await stripeService.cancelSubscription(context.user.email, context.stripeSubscription.id, context.user.stripeId);
            }
    
            if (result.status === 200) {
                const newUserInfo = await userService.getUser();
                context.setUser(newUserInfo);
                setConfirmation({
                    show: true,
                    text: result.data.message
                });
                if (action === 'cancel') {
                    context.setStripeSubscription(null);
                }
            } else {
                setConfirmation({
                    show: true,
                    text: result.data.error
                });
            }
        } catch (error) {
            setError(true, error.message);
        }
        context.setIsLoading(false);
    }

    const changePlan = async (product) => {
        if (!tokenExpiresSoon()) {
            setChangingPlan(false);
            action('change', product);
        } else {
            logoutToRenewToken();
        }
    };

    const cancelPlan = async () => {
        if (!tokenExpiresSoon()) {
            setUnsubscribing(false); 
            action('cancel');
        } else {
            logoutToRenewToken();
        }
    };

    const subscribeToPlan = async (product) => {
        if (!tokenExpiresSoon()) {
            context.setIsLoading(true);

            try {
                const stripe = await stripePromise;
                const { sessionId } = await stripeService.getSession(context.user.email, context.user.stripeId, product);

                const { error } = await stripe.redirectToCheckout({
                    sessionId
                });
                if (error) {
                    throw error;
                }
            } catch (err) {
                setError(true, err.message);
            }
            context.setIsLoading(false);
        } else {
            logoutToRenewToken();
        }
    };

    const getButtonText = (product) => {
        if (context.user.stripeId === null || context.stripeSubscription === null) {
            return 'Utiliser ce plan'
        } else {
            const plan = ROLE_TO_PLAN[context.user.roles[0]];
            if (product.id === plan) {
                return 'Annuler mon abonnement';
            } else {
                return 'Changer mon abonnement';
            }
        }
    }

    const getButtonClassName = (productId) => {
        const classes = ['bottom-btn'];
        if (productId === ROLE_TO_PLAN[context.user.roles[0]]) {
            classes.push('unsubscribe');
        }
        return classes.join(' ');
    }

    const getSubscriptionCardClass = (productId) => {
        const classes = ['position-relative h-100'];
        if (productId === ROLE_TO_PLAN[context.user.roles[0]]) {
            classes.push('active-subscription');
        }
        return classes.join(' ');
    }

    /**
     * List product plans
     * @param productPlans - array of product plans created in Stripe account that a user can subscribe to
     */
    const displayProductPlans = (productPlans) => {
        if (productPlans && productPlans.length) {
            return productPlans.map((product, i) => {
                return (
                    <Col xs={12} md={4} lg={4} key={i}>
                        <SubscriptionPlanCard className={getSubscriptionCardClass(product.id)}>
                            <SubscriptionPlanCardHeading>
                                <Col>
                                    <Row className="justify-content-center">
                                        {product.name}
                                    </Row>
                                </Col>
                            </SubscriptionPlanCardHeading>

                            <SubscriptionPlanCardPrice>
                                {product.price}&nbsp;<CurrencySymbol>€</CurrencySymbol>
                            </SubscriptionPlanCardPrice>

                            <SubscriptionPlanCardSubHeading>
                                {product.nbRequest}
                            </SubscriptionPlanCardSubHeading>

                            <SubscriptionPlanCardSubHeading>
                                {product.description}
                                </SubscriptionPlanCardSubHeading>

                            <SubscriptionPlanCardSubHeading style={{ borderBottom: 'none' }}>
                                {product.users}
                            </SubscriptionPlanCardSubHeading>

                            <br />
                            <br />
                            {product.id !== '' && (
                                <Button className={getButtonClassName(product.id)} block={true} onClick={() => handleClick(product.id)}>
                                    {getButtonText(product)}
                                </Button>
                            )}
                        </SubscriptionPlanCard>
                    </Col>
                );
            });
        }
        return 'No existing product plans';
    };

    return (
        <>
            <SubscriptionPlansWrapper>
                <Row>{displayProductPlans(PRODUCT_PLANS)}</Row>
            </SubscriptionPlansWrapper>
            <ConfirmationModal 
                isOpen={unsubscribing}
                question="Voulez-vous vraiment annuler votre abonnement ? (immédiat)"
                confirmationText="Confirmer"
                confirmationColor="danger"
                onConfirm={() => cancelPlan()}
                onCancel={() => setUnsubscribing(false)}  
            />
            <ConfirmationModal 
                isOpen={changingPlan}
                question="Voulez-vous vraiment changer d'abonnement ?"
                confirmationText="Confirmer"
                confirmationColor="primary"
                onConfirm={() => changePlan(productId)}
                onCancel={() => setChangingPlan(false)}  
            />
            <LoadingModal isOpen={context.isLoading} />
            <InformationModal isOpen={hasError} information={errorMessage} color="danger" onContinue={() => setError(false)} />
            <InformationModal isOpen={confirmation.show} information={confirmation.text} color="primary" onContinue={() => setConfirmation({ show: false, text: '' })} />

        </>
    );
};

export default SubscribeToProduct;
