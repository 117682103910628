/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";1

import AuthContext from 'contexts/AuthContext';
import AuthService from 'services/AuthService';

var ps;

class Sidebar extends React.Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.authService = new AuthService();
		this.activeRoute.bind(this);
	}

	state = {
		collapseOpen: false,
	};

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	}

	// toggles collapse between opened and closed (true/false)
	toggleCollapse = () => {
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};

	// closes the collapse
	closeCollapse = () => {
		this.setState({
			collapseOpen: false,
		});
	};

	// creates the links that appear in the left menu / Sidebar
	createLinks = (routes) => {
		return routes.map((prop, key) => {
			if (prop.hide) return;
			
			return (
				<NavItem key={key}>
					{prop.path && prop.layout ? (
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							onClick={this.closeCollapse}
							activeClassName='active'>
							<i className={prop.icon} />
							{prop.name}
						</NavLink>
					) : (
						<>
							<hr className='my-3' />
							<h6 className='navbar-heading text-primary d-flex justify-content-center'>
								Documentation
							</h6>
						</>
					)}
				</NavItem>
			);
		});
	};

	render() {
		const { bgColor, routes, logo } = this.props;
		let navbarBrandProps;
		if (logo && logo.innerLink) {
			navbarBrandProps = {
				to: logo.innerLink,
				tag: Link,
			};
		} else if (logo && logo.outterLink) {
			navbarBrandProps = {
				href: logo.outterLink,
				target: "_blank",
			};
		}
		return (
			<Navbar
				className='navbar-vertical fixed-left navbar-light bg-white'
				expand='md'
				id='sidenav-main'>
				<Container fluid>
					{/* Toggler */}
					<button
						className='navbar-toggler'
						type='button'
						onClick={this.toggleCollapse}>
						<span className='navbar-toggler-icon' />
					</button>
					{/* Brand */}
					{logo ? (
						<NavbarBrand className='pt-0' {...navbarBrandProps}>
							<img
								alt={logo.imgAlt}
								className='navbar-brand-img'
								src={logo.imgSrc}
							/>
						</NavbarBrand>
					) : null}
					{/* Collapse */}
					<Collapse navbar isOpen={this.state.collapseOpen}>
						{/* Collapse header */}
						<div className='navbar-collapse-header d-md-none'>
							<Row>
								{logo ? (
									<Col className='collapse-brand' xs='6'>
										{logo.innerLink ? (
											<Link to={logo.innerLink}>
												<img alt={logo.imgAlt} src={logo.imgSrc} />
											</Link>
										) : (
											<a href={logo.outterLink}>
												<img alt={logo.imgAlt} src={logo.imgSrc} />
											</a>
										)}
									</Col>
								) : null}
								<Col className='collapse-close' xs='6'>
									<button
										className='navbar-toggler'
										type='button'
										onClick={this.toggleCollapse}>
										<span />
										<span />
									</button>
								</Col>
							</Row>
						</div>
						<Nav navbar>{this.createLinks(routes)}</Nav>
						<Nav className='mb-md-3 nav-bottom ' navbar>
							<NavItem className='cursor-pointer'>
								<NavLink onClick={this.props.onContact}>
									<i className='ni ni-spaceship' />
									Nous contacter
								</NavLink>
							</NavItem>
							<NavItem className='cursor-pointer'>
								<NavLink onClick={this.props.onLogout}>
									<i className='ni ni-key-25 text-info"' />
									Se déconnecter
								</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		);
	}
}

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	onLogout: PropTypes.func.isRequired,
	onContact: PropTypes.func.isRequired,
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};

export default Sidebar;
