import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InvoicesHeader from './InvoicesHeader';
import Invoice from './Invoice';
import { Table, Row, Spinner } from 'reactstrap';

const Invoices = ({ invoices, loading }) => {
    const fields = ['Status', 'Montant', 'Date', '']

    const getContent = () => {
        if (invoices.length) {
            return (
                <Table className="align-items-center table-flush">
                    <InvoicesHeader fields={fields} />
                    <tbody>
                        {invoices.map(invoice => {
                            return <Invoice key={invoice.id} invoice={invoice} />
                        })}
                    </tbody>
                </Table>
            )
        } else {
            return (
                <Row className="justify-content-center mb-3">
                    <h4>Vous n'avez aucune facture</h4>
                </Row>
            )
        }
    }
    
    return (
        <Fragment>
            {loading ? (
                <Row className="justify-content-center mb-3">
                    <Spinner color="primary" />
                </Row>
            ) : getContent() }
        </Fragment>
    )
}

Invoices.defaultProps  = {
    loading: false
}

Invoices.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool
}

export default Invoices;