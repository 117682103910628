const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value) => {
    const emailCorrect = EMAIL_REG.test(value);
    return {
        error: !emailCorrect,
        message: emailCorrect ? '' : `Votre email n'est pas au bon format.`,
    };
};

export const minLength = (value, k) => {
    const correctLength = value.length >= k;
    return {
        error: !correctLength,
        message: correctLength ? '' : `Le champ doit avoir minimum ${k} caractères.`,
    };
};

export const maxLength = (value, k) => {
    const correctLength = value.length <= k;
    return {
        error: !correctLength,
        message: correctLength ? '' : `Le champ doit avoir maximum ${k} caractères.`,
    };
};

export const isRequired = (value) => {
    const correctValue = value.trim() !== '';
    return {
        error: !correctValue,
        message: correctValue ? '' : 'Le champ ne peut pas être vide.',
    };
};

export const same = (value1, value2) => {
    const areSame = value1 === value2;
    return {
        error: !areSame,
        message: areSame ? '' : 'Les valeurs ne sont pas égales.',
    };
};

export default class Validator {
    /**
     * Create a validator for a form
     * @param {Object} fields - All the fields (with their names as key)
     * @param {string} fields[field].value - Value of the input
     * @param {Object[]} fields[field].validations - The rules that the input needs to follow
     * @param {function} fields[field].validations[].rule - One of the function above or a custom one
     * @param {any[]} fields[field].validations[].params - Parameters of the validation function (rule)
     */
    constructor(fields) {
        this.fields = fields;
    }

    validate() {
        const errors = [];
        const fieldKeys = Object.keys(this.fields);

        fieldKeys.forEach((key) => {
            if (this.fields[key].validations) {
                const error = this.validateField(key);
                if (error) {
                    errors.push({
                        field: key,
                        message: error,
                    });
                }
            }
        });
        return errors;
    }

    validateField(field) {
        const { value, validations } = this.fields[field];

        // Each field can have multiple validations
        for (let i = 0; i < validations.length; i++) {
            const { rule, params } = validations[i];
            let result = null;

            // Execute function which has parameters
            if (params) {
                result = rule(value, ...params);
            } else {
                result = rule(value);
            }

            // Just get the first error of the fields
            if (result.error) {
                return result.message;
            }
        }
        return null;
    }

    allValid() {
        const errors = this.validate();
        return errors.length === 0;
    }
}
