/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { useHistory } from 'react-router-dom';
import SubscriptionSuccess from 'components/Subscription/SubscriptionSuccess';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import InformationModal from 'components/Modal/InformationModal';

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row } from 'reactstrap';

// core components
import Header from 'components/Headers/Header.js';
import SubscribeToProducts from 'components/Subscription/SubscribeToProducts';

const MonAbonnement = () => {
    const history = useHistory();

    return (
        <>
            <Header />
            <Container className=" mt--7" fluid>
                <Row>
                    <div className=" col">
                        <Card className=" shadow">
                            <CardHeader className=" bg-transparent">
                                <h3 className=" mb-0">Mon abonnement</h3>
                            </CardHeader>
                            <CardBody>
                                <SubscribeToProducts />
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
            <PrivateRoute path="/admin/mon-abonnement/success/:sessionId" component={SubscriptionSuccess} />
            <PrivateRoute path="/admin/mon-abonnement/failure" component={() => <InformationModal isOpen={true} information="Vous avez annulé votre achat." color="danger" onContinue={() => history.replace('/admin/mon-abonnement')} />} />
        </>
    );
};

export default MonAbonnement;
