import AuthService from 'services/AuthService';
import { DB_URL, ROLE_USER_MAX_REQUEST, ROLE_PRO_MAX_REQUEST, ROLE_BUSINESS_MAX_REQUEST, ROLE_ADMIN_MAX_REQUEST } from 'config';
import API from './API';
import EmailError from 'errors/EmailError';

export default class UserService {
    constructor() {
        this.authService = new AuthService();
        this.api = DB_URL;
    }

    getMaxRequest(role) {
        switch (role) {
            case 'ROLE_USER': return ROLE_USER_MAX_REQUEST;
            case 'ROLE_PRO': return ROLE_PRO_MAX_REQUEST;
            case 'ROLE_BUSINESS': return ROLE_BUSINESS_MAX_REQUEST;
            case 'ROLE_ADMIN': return ROLE_ADMIN_MAX_REQUEST;
            default: return ROLE_USER_MAX_REQUEST;
        }
    }

    async updateUser(newUser, userId) {
        const token = this.authService.getToken();
        try {
            const { status, data } = await API.execute(`${this.api}${userId}`, token, 'PUT', newUser);
            if (status === 200) {
                const updateUser = data;
                return updateUser;
            }

            const error = data;
            if (error.violations && error.violations[0]) {
                throw new EmailError(); 
            }
            throw new Error('La modification a échoué.');
        } catch (e) {
            throw e;
        }
    }

    async getUser() {
        try {
            const token = this.authService.getToken();
            const { status, data: user } = await API.execute(`${this.api}/users`, token);
            if (status === 200) {
                const userWithRequest = {
                    ...user["hydra:member"][0],
                    maxRequest: this.getMaxRequest(user["hydra:member"][0].roles[0])
                }
                return userWithRequest;
            } else {
                return null;
            }
        } catch (e) {
            throw e;
        }
    }

    async getRole() {
        try {
            const user = await this.getUser();
            return user.roles[0];
        } catch (e) {
            throw e;
        }
    }
}