/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://api-annuaire-sante.fr/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://api-annuaire-sante.fr)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
	render() {
		return (
			<>
				<footer className='py-5'>
					<Container>
						<Row className='d-flex align-items-center justify-content-center'>
							<Col xl='12'>
								<div className='copyright text-center text-white'>
									© 2020{" "}
									<a
										className='text-white ml-1'
										href='https://api-annuaire-sante²?ref=adr-auth-footer'
										target='_blank'>
										Api-annuaire-sante.fr
									</a>
								</div>
							</Col>
						</Row>
					</Container>
				</footer>
			</>
		);
	}
}

export default Login;
