/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, Fragment, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Input, Form, Spinner, Button, UncontrolledTooltip } from "reactstrap";
import Header from "components/Headers/Header.js";
import LangageExemples from "components/LangageExemples/LangageExemples";
import pushSlack from 'services/functions/webhooks';

import { DB_URL, PROFESSIONS, SAVOIR_FAIRE, SLACK_FULL } from "config";
import AuthContext from "contexts/AuthContext";
import AuthService from "services/AuthService";
import API from "services/API";

const Exemples = () => {
    const context = useContext(AuthContext);
    const history = useHistory();
    const authService = new AuthService();
    const isMounted = useRef(true);

    const ENDPOINT = DB_URL;
    const RESSOURCE = "/professionnel_de_santes";

    // State
    const [requestUrl, setRequestUrl] = useState(ENDPOINT);
    const [urlCopied, setUrlCopied] = useState(false);
    const [jsonParams, setJsonParams] = useState({
        prenom: "",
        nom: "",
        id: "",
        libelleDepartement: "",
        libelleCommune: "",
        page: "",
        libelleProfession: "",
        libelleSavoirFaire: "",
    });
    const [result, setResult] = useState({
        show: true,
        json: null,
    });
    const [maxRequestReached, setMaxRequestReached] = useState(false);

    const handleChange = (value, target) => {
        if (target === 'id') {
            setJsonParams({
                ...jsonParams,
                prenom: "",
                nom: "",
                id: value,
                libelleDepartement: "",
                libelleCommune: "",
                page: "",
            });
        } else {
            setJsonParams({ 
                ...jsonParams, 
                [target]: value,
                id: ""
            });
        }
    };

    const removeId = () => {
        setJsonParams({ 
            ...jsonParams,
            id: ""
        });
    }

    const handleClick = (checked, value, target) => {
        if (jsonParams.id === "") {
            setJsonParams({
                ...jsonParams,
                [target]: checked ? value : "",
            });
        }
    };

    useEffect(() => {
        if (context.isLoading) {
            context.setIsLoading(false);
        }
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        let url = "";
        let id = "";
        const keys = Object.keys(jsonParams);

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];

            if (k === "id" && jsonParams[k].length && !isNaN(jsonParams[k])) {
                id = `/${jsonParams[k]}`;
                break;
                
            } else if ((k === "libelleProfession" && jsonParams[k].length) || (k === "libelleSavoirFaire" && jsonParams[k].length)) {
                const splited = jsonParams[k].toString().split(",");
                splited.forEach((element) => {
                    url += url === "" 
                        ? "?" + k + "[]=" + element 
                        : "&" + k + "[]=" + element;
                });

            } else if (k !== "id" && jsonParams[k] && jsonParams[k].length) {
                url += url === "" 
                ? "?" + k + "=" + jsonParams[k] 
                : "&" + k + "=" + jsonParams[k];
            }
        }
        setRequestUrl(ENDPOINT + RESSOURCE + id + url);
        setUrlCopied(false);
    }, [jsonParams]);

    useEffect(() => {
        if (!maxRequestReached && context.user.nhitsToday >= context.user.maxRequest) {
            setMaxRequestReached(true);
        }
    }, [context.user.nhitsToday]);

    const triggerRequest = async () => {
        context.setIsLoading(true);
        try {
            const token = authService.getToken();
            const response = await API.execute(requestUrl, token);

            pushSlack(SLACK_FULL, { 
                type: 'TEXT',
                content: [`${context.user.email}: Exécution requête: ${requestUrl}`]
            });
            
            if (isMounted.current) {
                setResult({
                    show: true,
                    json: 
                        response.data['hydra:member'] 
                        ? {
                            'hydra:member': response.data['hydra:member']
                        } 
                        : response.data,
                });
            }
            context.setUser((prevUser) => {
                if (!response.data.error && maxRequestReached) {
                    return {
                        ...prevUser,
                        nhitsTotal: prevUser.nhitsTotal + 1,
                        nhitsToday: 1,
                    }
                } else if (response.data.error) {
                    return prevUser
                } else {
                    return {
                        ...prevUser,
                        nhitsTotal: prevUser.nhitsTotal + 1,
                        nhitsToday: prevUser.nhitsToday + 1,
                    };
                }
            });
        } catch (error) {
            console.error(error);
        }
        context.setIsLoading(false);
    };

    const toggleResult = () => {
        setResult((prevResult) => {
            return {
                ...prevResult,
                show: !prevResult.show,
            };
        });
    };

    return (
        <>
            <Header />
            <Container className=" mt--7" fluid>
                <Row>
                    <Col>
                        <Card className=" shadow">
                            <CardHeader className=" bg-transparent">
                                <h3 className=" mb-3">Mon constructeur de requête</h3>
                                <Row className="m-0">
                                    <button
                                        className={maxRequestReached ? "btn btn-blocked" : "btn btn-primary"}
                                        onClick={(e) => triggerRequest(e)}
                                    >
                                        Exécuter
                                    </button>
                                    {maxRequestReached && (
                                        <button className="btn btn-primary" onClick={() => history.push("/admin/mon-abonnement")}>
                                            Changer d'abonnement
                                        </button>
                                    )}
                                </Row>
                            </CardHeader>

                            <CardBody>
                                {(context.isLoading || result.json) && (
                                    <Col className="border-bottom mb-3 pb-2">
                                        {context.isLoading && (
                                            <Row className="align-items-center m-3">
                                                <Spinner className="m-auto" />
                                            </Row>
                                        )}
                                        {result.json && !context.isLoading && (
                                            <Fragment>
                                                <Col>
                                                    <Row>
                                                        <h4 className="m-1">Résultat:</h4>
                                                        <button onClick={() => toggleResult()} className="btn btn-link p-0 m-1">
                                                            {result.show ? "Masquer" : "Voir"}
                                                        </button>
                                                    </Row>
                                                </Col>
                                                {result.show && <pre>{JSON.stringify(result.json, null, 2)}</pre>}
                                            </Fragment>
                                        )}
                                    </Col>
                                )}
                                <Row className="mb-4 mx-2">
                                    <pre className="m-0 url-display">{requestUrl}</pre>

                                    <CopyToClipboard className="request-option-button " text={requestUrl} onCopy={() => setUrlCopied(true)} >
                                        <Button color="primary" id="clipboard-btn">
                                            <i className="ni ni-single-copy-04 text-white" />
                                        </Button>
                                    </CopyToClipboard>
                                    <UncontrolledTooltip delay={0} trigger="hover focus" target="clipboard-btn" >
                                        {urlCopied ? "Copié" : "Copier dans le presse papier"}
                                    </UncontrolledTooltip>

                                    <Button color="primary" className="request-option-button " onClick={(e) => triggerRequest(e)}>
                                        <i className="ni ni-curved-next text-white" />
                                    </Button>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">Informations</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-prenom">
                                                                Prénom
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-prenom"
                                                                placeholder="Jean"
                                                                onChange={(e) => handleChange(e.target.value, "prenom")}
                                                                type="text"
                                                                value={jsonParams.prenom}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-nom">
                                                                Nom
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-nom"
                                                                placeholder="Durand"
                                                                onChange={(e) => handleChange(e.target.value, "nom")}
                                                                type="text"
                                                                value={jsonParams.nom}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-libelleCommune">
                                                                Ville
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-libelleCommune"
                                                                placeholder="Lyon"
                                                                onChange={(e) => handleChange(e.target.value, "libelleCommune")}
                                                                type="text"
                                                                value={jsonParams.libelleCommune}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-libelleDepartement">
                                                                Département
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-libelleDepartement"
                                                                placeholder="Rhône-Alpes"
                                                                type="text"
                                                                onChange={(e) => handleChange(e.target.value, "libelleDepartement")}
                                                                value={jsonParams.libelleDepartement}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-id">
                                                                Id
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-id"
                                                                placeholder="1"
                                                                type="number"
                                                                min="1"
                                                                onChange={(e) => handleChange(e.target.value, "id")}
                                                                value={jsonParams.id}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-page">
                                                                Page
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-page"
                                                                placeholder="1"
                                                                type="number"
                                                                min="1"
                                                                onChange={(e) => handleChange(e.target.value, "page")}
                                                                value={jsonParams.page}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">Professions</h6>
                                            <div className="pl-lg-2">
                                                <Row>
                                                    <Col md="12">
                                                        <ul>
                                                            {PROFESSIONS &&
                                                                PROFESSIONS.map((profession, index) => (
                                                                    <li key={index} onClick={() => removeId()}>
                                                                        <label className="">
                                                                            <input
                                                                                name="isGoing"
                                                                                type="checkbox"
                                                                                className="mr-3"
                                                                                value={profession}
                                                                                checked={profession === jsonParams.libelleProfession}
                                                                                disabled={jsonParams.id !== ""}
                                                                                onChange={(e) =>
                                                                                    handleClick(e.target.checked, e.target.value, "libelleProfession")
                                                                                }
                                                                            />
                                                                            {profession}
                                                                        </label>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">Savoir Faire</h6>
                                        <div className="pl-lg-2">
                                            <Row>
                                                <Col md="12">
                                                    <ul>
                                                        {SAVOIR_FAIRE &&
                                                            SAVOIR_FAIRE.map((savoirFaire, index) => (
                                                                <li key={index}>
                                                                    <label className="">
                                                                        <input
                                                                            name="isGoing"
                                                                            type="checkbox"
                                                                            className="mr-3"
                                                                            value={savoirFaire}
                                                                            checked={savoirFaire === jsonParams.libelleSavoirFaire}
                                                                            disabled={jsonParams.id !== ""}
                                                                            onChange={(e) =>
                                                                                handleClick(e.target.checked, e.target.value, "libelleSavoirFaire")
                                                                            }
                                                                        />

                                                                        {savoirFaire}
                                                                    </label>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <LangageExemples url={requestUrl} token={localStorage.getItem("token")} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Exemples;
