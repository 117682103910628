export const LOCAL_DB_URL = 'http://127.0.0.1:8000';
export const ONLINE_DB_URL = 'https://production.api-annuaire-sante.fr';
export const DB_URL = document.location.href.includes("localhost") ? LOCAL_DB_URL : ONLINE_DB_URL;

export const ROLE_USER_MAX_REQUEST = 30;
export const ROLE_PRO_MAX_REQUEST = 500;
export const ROLE_BUSINESS_MAX_REQUEST = Infinity;
export const ROLE_ADMIN_MAX_REQUEST = Infinity;

export const SLACK_BASE = 'https://hooks.slack.com/services/TKDAZ80PJ/';
export const SLACK_TEST= 'B012ZPAF10B/E55AvB6tyyRQrSIg321UZrmJ';
export const SLACK_AAS = 'B0147T8CEDR/76xHDrJzWwt6hrcX9jXkyUY4';
export const SLACK_FULL = SLACK_BASE + (document.location.href.includes("localhost") ? SLACK_TEST : SLACK_AAS);

export const PROFESSIONS = [
    "Opticien-Lunetier",
    "Infirmier",
    "Orthophoniste",
    "Diététicien",
    "Psychomotricien",
    "Manipulateur ERM",
    "Technicien de laboratoire médical",
    "Ergothérapeute",
    "Orthopédiste-Orthésiste",
    "Audioprothésiste",
    "Orthoptiste",
    "Orthoprothésiste",
    "Podo-Orthésiste",
    "Epithésiste",
    "Chirurgien-Dentiste",
    "Pharmacien",
    "Sage-Femme",
    "Médecin",
    "Masseur-Kinésithérapeute",
    "Pédicure-Podologue",
    "Oculariste",
];

export const SAVOIR_FAIRE = [
    "Qualifié en Médecine Générale",
    "Spécialiste en Médecine Générale",
    "Psychiatrie",
    "Orthopédiedento-faciale",
    "Médecine Générale",
    "Neuro-psychiatrie",
    "Cardiologie",
    "Médecine du travail",
    "Ophtalmologie",
    "Gynécologie médicale",
    "Rhumatologie",
    "Gastro-entérologie et hépatologie",
    "Endocrinologie",
    "Néphrologie",
    "Gynécologie médicale et obstétrique",
    "Chirurgie",
    "Biologie",
    "Radio-diagnostic",
    "Anesthesie-réanimation",
    "Neurologie",
    "Pédiatrie",
    "Oncologie",
    "Dermatologie",
    "Médecine interne",
    "Pneumologie",
    "Oto-rhino-laryngologie",
    "Allergologie",
    "Anatomie",
    "Santé publique et médecine sociale",
    "Gériatrie",
    "Oncologie",
    "Stomatologie",
    "Médecine nucléaire",
    "Gynécologie-obstétrique",
    "Médecine physique et réadaptation",
    "Médecine intensive-réanimation",
    "Médecine vasculaire",
    "Radio-thérapie",
    "Maladies",
    "Gynéco-obstétrique et Gynéco médicale option Gynéc...",
    "Neuro-chirurgie",
    "O.R.L et chirurgie cervico faciale",
    "Hématologie",
    "Médecine Bucco-Dentaire",
    "Génétique médicale",
    "Obstétrique",
    "Qualification",
    "Médecine légale et expertises médicales",
    "Médecine d'urgence",
    "Urologie",
    "Hématologie",
    "Recherche",
    "Médecine interne et immunologie clinique",
    "Endocrinologie",
    "Diabétologie",
    "Nutrition"
];