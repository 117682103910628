import React from 'react';
import { Row, Col, TabPane } from 'reactstrap';
import PropType from 'prop-types';

// Couleurs pour le code
import SyntaxHighlighter from 'react-syntax-highlighter';
import tomorrowNightEighties from 'react-syntax-highlighter/dist/esm/styles/hljs/tomorrow-night-eighties';

const Code = ({ code, langage, tabId }) => {
    return (
        <TabPane tabId={tabId}>
          <Row>
            <Col className="px-4">
                <SyntaxHighlighter language={langage} style={tomorrowNightEighties}>
                    {code}
                </SyntaxHighlighter>
            </Col>
          </Row>
        </TabPane>
    )
}

Code.propTypes = {
  code: PropType.string.isRequired,
  langage: PropType.string.isRequired,
  tabId: PropType.string.isRequired

}

export default Code;