import React from 'react';
import { Row, Spinner } from 'reactstrap';

const FullScreenSpinner = () => {
    return (
        <Row className="w-100 justify-content-center align-items-center loader bg-white">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img className="mb-4" src={require("assets/img/brand/logo-api-annuaire-sante.png")} alt="Logo annuaire santé"/>
                <Spinner color="primary" />
            </div>
		</Row>
    );
}

export default FullScreenSpinner;