const getCode = (language, url, token, email) => {
    switch (language) {

        case 'javascript': 
            return `

// Récupération du token de connexion
const myHeaders = new Headers({
    "Content-Type", "application/json"
});

const raw = JSON.encode({
    "username": "${email}",
    "password": "votre_mdp"
});

const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
};

let token = '';
fetch("https://production.api-annuaire-sante.fr/login_check", requestOptions)
  .then(response => response.json())
  .then(result => {
      token = result.token;
  })
  .catch(error => console.log('error', error));

// ========================================  

// Utilisation du token dans une requête
const myHeaders = new Headers({
    "Authorization": "Bearer " + token
});

const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
};
    
fetch('${url}', requestOptions)
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`;

    case 'php':
        return `
require_once 'HTTP/Request2.php';

// Récupération du token de connexion
$request = new HTTP_Request2('https://production.api-annuaire-sante.fr/login_check', HTTP_Request2::METHOD_POST);
$request->setConfig(array(
    'follow_redirects' => TRUE
));
$request->setBody('{\n	"username": "${email}",\n	"password": "votre_mdp"\n}');

$token = '';
try {
    $response = $request->send();
    if ($response->getStatus() == 200) {
        $result =  json_decode($response->getBody());
        $token = $result["token"];
    }
    else {
        echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' .
        $response->getReasonPhrase();
    }
} catch(HTTP_Request2_Exception $e) {
    echo 'Error: ' . $e->getMessage();
}

// ========================================  

// Utilisation du token dans une requête
$request = new HTTP_Request2('${url}', HTTP_Request2::METHOD_GET);
$request->setConfig(array(
    'follow_redirects' => TRUE
));
$request->setHeader(array(
    'Authorization' => 'Bearer ' . $token
));

try {
    $response = $request->send();
    if ($response->getStatus() == 200) {
        echo $response->getBody();
    } else {
        echo 'Unexpected HTTP status: ' . $response->getStatus() . ' ' . $response->getReasonPhrase();
    }
} catch(HTTP_Request2_Exception $e) {
    echo 'Error: ' . $e->getMessage();
}`;
        case 'java':
            return `

// Récupération du token de connexion
OkHttpClient client = new OkHttpClient().newBuilder()
    .build();

MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{'username': '${email}','password': 'votre_mdp'}");

Request request = new Request.Builder()
    .url("https://production.api-annuaire-sante.fr/login_check")
    .method("POST", body)
    .addHeader("Content-Type", "application/json")
    .build();

Response response = client.newCall(request).execute();
String content = response.body().string();
JSONObject result = new JSONObject(content);
String token = result.getString("token");

// ========================================  

// Utilisation du token dans une requête
OkHttpClient client = new OkHttpClient().newBuilder()
    .build();

Request request = new Request.Builder()
    .url("${url}")
    .method("GET", null)
    .addHeader("Authorization", "Bearer " + token)
    .build();

Response response = client.newCall(request).execute();`;

        case 'curl':
            return `

Récupération du token de connexion:
curl --location --request POST 'https://production.api-annuaire-sante.fr/login_check' \
--header 'Content-Type: application/json' \
--data-raw '{
    "username": "${email}",
    "password": "votre_mdp"
}'

========================================  

Utilisation du token dans une requête:
curl --location --request GET '${url}' \\
--header 'Authorization: Bearer {votre_token}'          
`;
        default: return '';
    }
}

export default getCode;