import React, { useState, useContext } from 'react';
import PropType from 'prop-types';

import { Nav, Col, TabContent, Card, CardHeader, CardBody } from 'reactstrap';
import Langage from 'components/LangageExemples/Langage';
import Code from 'components/LangageExemples/Code';
import getCode from './utils/codeSnippet';    

import AuthContext from 'contexts/AuthContext';

const LanguageExamples = ({ url, token }) => {
    const [activeTab, setActiveTab] = useState('1');
    const context = useContext(AuthContext);

    const toggleLangage = (tabId) => {
        setActiveTab(tabId);
    }

    return (
            <Card>
                <CardHeader>
                    <h3>Code</h3>
                </CardHeader>
                <CardBody>
                    <Col className="code-snippet p-0">
                        <Nav tabs>
                            <Langage active={activeTab === "1"} onClick={() => toggleLangage("1")} langage="Javascript" />
                            <Langage active={activeTab === "2"} onClick={() => toggleLangage("2")} langage="PHP" />
                            <Langage active={activeTab === "3"} onClick={() => toggleLangage("3")} langage="Java" />
                            <Langage active={activeTab === "4"} onClick={() => toggleLangage("4")} langage="Curl" />
                        </Nav>
                        <TabContent activeTab={activeTab} >
                            <Code tabId="1" code={getCode('javascript', url, token, context.user.email)} langage="javascript" />
                            <Code tabId="2" code={getCode('php', url, token, context.user.email)} langage="php" />
                            <Code tabId="3" code={getCode('java', url, token, context.user.email)} langage="java" />
                            <Code tabId="4" code={getCode('curl', url, token, context.user.email)} langage="curl" />
                        </TabContent>
                    </Col>
                </CardBody>
            </Card>
    )
}

const areEqual = (prevProps, nextProps) => {
    return prevProps.url === nextProps.url && prevProps.token === nextProps.token; 
}

LanguageExamples.propTypes = {
    url: PropType.string.isRequired,
    token: PropType.string.isRequired
}

export default React.memo(LanguageExamples, areEqual);