import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import PropType from "prop-types";

const PrivateRoute = ({ path, component }) => {
    const context = useContext(AuthContext);
    return context.isAuthenticated ? (
        <Route path={path} component={component} />
    ) : (
        <Redirect to="/auth/login" />
    );
};

PrivateRoute.propTypes = {
    path: PropType.string.isRequired,
    component: PropType.elementType.isRequired,
};

export default PrivateRoute;
