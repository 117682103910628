/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

import { Container } from "reactstrap";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import ContentModal from 'components/Modal/ContentModal';
import ContactForm from 'components/Forms/ContactForm';

import AuthContext from 'contexts/AuthContext';
import AuthService from 'services/AuthService';

import routes from "routes.js";

class Admin extends React.Component {
	static contextType = AuthContext;

	state = {
		showContactForm: false
	}

	constructor() {
		super();
		this.authService = new AuthService();
	}

	resetError() {
		if (this.context.hasError) {
			this.context.setHasError(false);
			this.context.setErrorMessage('');
		}
	}

	componentDidUpdate() {
		const isTokenStilValid = this.authService.isAuthenticated();
		if (!isTokenStilValid) {
			this.context.setIsAuthenticated(false);
			this.authService.logout();
		}
		this.resetError();
	}

	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/admin") {
				return (
					<PrivateRoute
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	}

	getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (
				this.props.location.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return "Brand";
	}

	logout = () => {
		this.authService.logout();
		this.context.setIsAuthenticated(false);
	}

	toggleContactForm = () => {
		this.setState(prevState => {
			return {
				...prevState,
				showContactForm: !prevState.showContactForm
			};
		});
	}

	render() {
		return (
			<>
				<Sidebar
					{...this.props}
					routes={routes}
					logo={{
						innerLink: "/admin/index",
						imgSrc: require("assets/img/brand/logo-api-annuaire-sante.png"),
						imgAlt: "...",
					}}
					onLogout={() => this.logout()}
					onContact={this.toggleContactForm}
				/>
				<div className='main-content' ref='mainContent'>
					<Switch>
						{this.getRoutes(routes)}
						<Redirect from='*' to='/admin/index' />
					</Switch>
					<Container fluid>
						<AdminFooter />
					</Container>
					<ContentModal title="Contact" show={this.state.showContactForm}>
						<ContactForm onSubmit={this.toggleContactForm} onClose={this.toggleContactForm}/>
					</ContentModal>
				</div>
			</>
		);
	}
}

export default Admin;
