export const TEST_KEY = 'pk_test_51GqJHmALIS2ZzDfAGQS0Lj4kSpABNfN4XPmnfVNkBbWxUIdAOkcEljFhAWqEyICsqwIJw2GNHKx2ogzSOLDSiYDp00sfttugx8';
export const LIVE_KEY =  'pk_live_DiLwKOKYnzNN7SbMImFQwnCP00IM7Q0vMh';

export const STRIPE_PUBLISHABLE_KEY = document.location.href.includes("localhost") ? TEST_KEY : LIVE_KEY;

const PRO = 'price_1Grkj3ALIS2ZzDfAGFusDBs0';
const BUSINESS = 'price_1GrkixALIS2ZzDfA4TPPPOsG';

const PRO_TEST = 'price_1GrkXeALIS2ZzDfAiG9sCu3q';
const BUSINESS_TEST = 'price_1GrkiqALIS2ZzDfACGqGEigp';

/** Stripe product plan ids */
export const STANDARD_PRODUCT_ID = "";
export const PREMIUM_PRODUCT_ID = document.location.href.includes("localhost") ? PRO_TEST : PRO;
export const ENTERPRISE_PRODUCT_ID = document.location.href.includes("localhost") ? BUSINESS_TEST : BUSINESS;

/** Stripe product plans */
export const PRODUCT_PLANS = [
	{
		id: STANDARD_PRODUCT_ID,
		name: "Starter",
		nbRequest: '30 requêtes/jour',
		description: "Pour commencer l'utilisation de l'API",
		users: "1 utilisateur",
		price: 0,
	},
	{
		id: PREMIUM_PRODUCT_ID,
		name: "Pro",
		nbRequest: '500 requêtes/jour',
		description: "Pour les utilisateurs de l'API",
		users: "1 utilisateur",
		price: 19.99,
	},
	{
		id: ENTERPRISE_PRODUCT_ID,
		name: "Business",
		nbRequest: 'Nombre de requêtes illimité',
		description:
			"Pour les entreprises qui ont besoin d'effectuer régulièrement des rêquetes",
		users: "10 utilisateurs",
		price: 59.99,
	},
];

export const ROLE_TO_PLAN = {
	ROLE_USER: STANDARD_PRODUCT_ID,
	ROLE_PRO: PREMIUM_PRODUCT_ID,
	ROLE_BUSINESS: ENTERPRISE_PRODUCT_ID
};
