export default class API {
    static async execute(url, token, method = 'GET', data = {}) {
        const headers = new Headers();
        let body = undefined;

        if (token) {
            headers.append('Authorization', 'Bearer ' + token);
        }
        if (method !== 'GET') {
            headers.append("Content-Type", "application/json");
            body = JSON.stringify(data);
        }

        try {
            const response = await fetch(url, { headers, body, method });
            const data = await response.json();
            return {
                status: response.status,
                data
            };
        } catch (error) {
            throw new Error('Une erreur est survenue. Essayez plus tard.');
        }
    } 

    static async executeWithoutToken(url, method = 'GET', data = {}) {
        try {
            const result = await this.execute(url, undefined, method, data);
            return result;
        } catch (error) {
            throw error;
        }
    }
}