import JwtDecode from "jwt-decode";
import CredentialsError from 'errors/CredentialsError';
import { DB_URL } from 'config';
import API from "./API";
import EmailError from 'errors/EmailError';

export default class AuthService {
    constructor() {
        this.api = DB_URL;
    }

    async authenticate (email, password) {
        try {
            const { data } = await API.executeWithoutToken(`${this.api}/login_check`, 'POST', { username: email, password });
            if (data.token) {
                const { token } = data;
                const tokenInfo = {
                    token,
                    ...this.decodeToken(token)
                } 

                this.addTokenToLocalStorage(token);
                this.removeLoginInfo();
                
                return tokenInfo;
            } else {
                throw new CredentialsError();
            }
        } catch (err) {
            if (err.name === 'CredentialsError') {
                throw err;
            }
            throw new Error('Une erreur est survenue. Essayez plus tard.');
        }
    }

    async sendForgottenPassEmail(email) {
        try {
            const { data } = await API.executeWithoutToken(`${this.api}/forgotten-password`, 'POST', { email });
            if (data) {
                if (data.state) {
                    return data;
                }
                throw new Error (data.error);
            }
            throw new Error ();
        } catch (e) {
            throw e;
        }
    }

    async resetPassword(email, newPassword, token) {
        try {
            const { data } = await API.executeWithoutToken(`${this.api}/forgotten-password`, 'POST', { email, token, password: newPassword });
            if (data) {
                if (data.state) {
                    return data.state;
                }
                throw new EmailError ('Mauvais email');
            }
            throw new Error ();
        } catch (e) {
            if (e.name === 'EmailError') {
                throw e;
            }
            throw new Error ('Une erreur est survenue. Essayez plus tard.');
        }
    }

    getTokenInfo() {
        const token = this.getToken();
        const info = this.decodeToken(token);
        return info;
    }

    isAuthenticated() {
        const token = localStorage.getItem('token');
        if (token) {
            if (!this.isTokenExpired()) {
                return true;
            }
            this.logout();
            return false;
        }
        return false;
    }

    isTokenExpired() {
        const token = this.getToken();
        if (token) {
            const { exp } = this.decodeToken(token); 
            if (exp * 1000 > new Date().getTime()) {
                return false;
            }
            this.setLoginInfo('Votre token a expiré.');
        }
        return true;
    }

    getLoginInfo() {
        const info = localStorage.getItem('login_info');
        if (!info || (info && info.trim() === '')) {
            if (info) {
                this.removeLoginInfo();
            }
            return null;
        }
        return info;
    }

    setLoginInfo(info) {
        localStorage.setItem('login_info', info);
    }

    removeLoginInfo() {
        localStorage.removeItem('login_info');
    }

    getToken() {
        if (this.isAuthenticated) {
            return localStorage.getItem('token');
        }
    }

    addTokenToLocalStorage(token) {
        localStorage.setItem('token', token);
    }

    logout() {
        localStorage.removeItem('token');
    }

    decodeToken(token) {
        return JwtDecode(token);
    }
}