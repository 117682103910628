import API from "./API";
import { DB_URL } from 'config';
import AuthService from 'services/AuthService';

export default class StripeService {
    constructor() {
        this.authService = new AuthService();
    }

    async getSubscription(stripeId) {
        const token = this.authService.getToken();

        try {
            if (stripeId) {
                const { data: subscription } = await API.execute(`${DB_URL}/stripe/customer/${stripeId}/subscription`, token);
                if (subscription && subscription.id) {
                    return {
                        id: subscription.id,
                        periodStart: subscription.current_period_start,
                        periodEnd: subscription.current_period_end,
                        plan: subscription.items.data[0].plan.id
                    }
                }
            }
            return null;
        } catch (e) {
            throw e;
        }
    }

    async getInvoices(stripeId) {
        const lightInvoices = [];
        const token = this.authService.getToken();

        try {
            const { data } = await API.execute(`${DB_URL}/stripe/customer/${stripeId}/invoices`, token);
            const invoices = data.data;
            if (invoices && invoices.length) {
                invoices.forEach(invoice => {
                    lightInvoices.push({
                        id: invoice.id,
                        billingReason: invoice.billing_reason,
                        amount: invoice.total,
                        status: invoice.status,
                        date: invoice.status_transitions.paid_at,
                        pdf: invoice.invoice_pdf
                    })
                });
                return lightInvoices;
            } else {
                return null;
            }
        } catch (e) {
            throw e;
        }
    }

    async getSession(email, stripeId, plan) {
        const token = this.authService.getToken();

        try {
            const { data: session } = await API.execute(`${DB_URL}/stripe/session`, token, 'POST', { email, plan, stripeId });
            if (session) {
                return session;
            }
            return null;
        } catch (e) {
            throw e;
        }
    }

    async newSubscription(email, sessionId, stripeId) {
        const token = this.authService.getToken();

        try {
            const result = await API.execute(`${DB_URL}/stripe/subscription`, token, 'POST', { email, sessionId, stripeId });
            return result;
        } catch (e) {
            throw e;
        }
    }

    async changeSubscription(email, plan, subscriptionId, stripeId) {
        const token = this.authService.getToken();

        try {
            const result = await API.execute(`${DB_URL}/stripe/subscription/update`, token, 'POST', { email, plan, stripeId, subscriptionId });
            return result;
        } catch (e) {
            throw e;
        }
    }

    async cancelSubscription(email, subscriptionId, stripeId) {
        const token = this.authService.getToken();

        try {
            const result = await API.execute(`${DB_URL}/stripe/subscription/cancel`, token, 'POST', { email, stripeId, subscriptionId });
            return result;
        } catch (e) {
            throw e;
        }
    }
}