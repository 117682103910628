import React from 'react';
import {  NavItem, NavLink } from 'reactstrap';
import PropType from 'prop-types';

const Langage = ({ langage, onClick, active }) => {
    return (
        <NavItem>
            <NavLink className={active ? 'active' : ''} onClick={onClick}>
                {langage}
            </NavLink>
        </NavItem>
    )
}

Langage.propTypes = {
    langage: PropType.string.isRequired,
    onClick: PropType.func,
    active: PropType.bool.isRequired
}

export default Langage;