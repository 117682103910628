/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index';
import MonAbonnement from 'views/MonAbonnement';
import Profile from 'views/Profile';
import Factures from 'views/Factures';
import Documentation from 'views/Documentation';
import Exemples from 'views/Exemples';
import Login from 'views/Login';
import ResetPassword from 'views/ResetPassword';

var routes = [
	{
		path: "/mon-abonnement",
		name: "Mon abonnement",
		icon: "ni ni-ui-04 text-primary",
		component: MonAbonnement,
		layout: "/admin",
	},
	{
		path: "/mon-compte",
		name: "Mon compte",
		icon: "ni ni-circle-08 text-primary",
		component: Profile,
		layout: "/admin",
	},
	{
		path: "/factures",
		name: "Mes factures",
		icon: "ni ni-paper-diploma text-primary",
		component: Factures,
		layout: "/admin",
	},
	{},
	{
		path: "/documentation",
		name: "Documentation technique",
		icon: "ni ni-single-copy-04 text-primary",
		component: Documentation,
		layout: "/admin",
	},
	{
		path: "/index",
		name: "Constructeur de requête",
		icon: "ni ni-palette text-primary",
		component: Exemples,
		layout: "/admin",
	},
	{
		path: "/login",
		name: "Login",
		icon: "ni ni-key-25 text-info",
		component: Login,
		layout: "/auth",
		hide: true
	},
	{
		path: "/reset-password/:token",
		name: 'Changer de mot de passe',
		component: ResetPassword,
		layout: "/auth",
		hide: true
	}
];
export default routes;
