import React, { useState, useEffect, useContext, Fragment, useRef } from 'react';
import Header from 'components/Headers/Header';
import AuthContext from 'contexts/AuthContext';
import { Container, Card, CardHeader } from 'reactstrap';
import StripeService from 'services/StripeService';
import Invoices from 'components/Invoices/Invoices';
import useError from 'hooks/useError';

const Factures = () => {
    const [invoices, setInvoices] = useState([]);
    const [hasError, errorMessage, setError] = useError();
    const context = useContext(AuthContext);
    const stripeService = new StripeService();
    const isMounted = useRef(true);

    useEffect(() => {
        const getInvoices = async () => {
            context.setIsLoading(true);
            if (context.user.stripeId) {
                try {
                    const invoices = await stripeService.getInvoices(context.user.stripeId);
                    if (isMounted.current) {
                        setInvoices(invoices);
                    }
                } catch (error) {
                    if (isMounted.current) {
                        setError(true, error.message);
                    }
                }
            }
            context.setIsLoading(false);
        };
        getInvoices();

        return () => {
            isMounted.current = false;
        }
    }, []);

    return (
        <Fragment>
            <Header />
            <Container className=" mt--7" fluid>
                <Card>
                    <CardHeader className="border-bottom-0">
                        <h3>Vos factures</h3>
                    </CardHeader>
                    <Invoices loading={context.isLoading} invoices={invoices} />
                </Card>
            </Container>
        </Fragment>
    );
};

export default Factures;
