import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Row, Button } from 'reactstrap';

const CANCEL_TEXT = 'Annuler';

const ConfirmationModal = ({ isOpen, question, confirmationText, confirmationColor, onConfirm, onCancel }) => {
    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <Row className="m-3 justify-content-center text-center">{question}</Row>
                <Row className="m-3 justify-content-center">
                    <Button onClick={onCancel}>{CANCEL_TEXT}</Button>
                    <Button color={confirmationColor} onClick={onConfirm}>{confirmationText}</Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}

ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    question: PropTypes.string.isRequired,
    confirmationText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmationColor: PropTypes.string
}

export default ConfirmationModal;