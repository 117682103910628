/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import AuthContext from 'contexts/AuthContext';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class UserHeader extends React.Component {
  static contextType = AuthContext;

  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="8" md="10">
                <h1 className="display-2 text-white">Bonjour {this.context.user.firstName}</h1>
                <p className="text-white mt-0 mb-5">
                  Bienvenue sur votre profil. Vous pouvez voir 
                  vos informations ainsi que les modifier.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
