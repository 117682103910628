import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import AuthContext from 'contexts/AuthContext';
import AuthService from 'services/AuthService';
import UserService from 'services/UserService';
import FullScreenSpinner from 'components/FullScreenSpinner/FullScreenSpinner';
import StripeService from 'services/StripeService';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [stripeSubscription, setStripeSubscription] = useState(null);
    const authService = new AuthService();
    const userService = new UserService();
    const stripeService = new StripeService();

    useEffect(() => {
        setIsAuthenticated(authService.isAuthenticated());
    });

    useEffect(() => {
        const getUserIfAuthenticated = async () => {
            if (authService.isAuthenticated() && !user && initialLoading) {
                try {
                    const user = await userService.getUser();
                    if (user) {
                        const stripeSub = await stripeService.getSubscription(user.stripeId);
                        if (stripeSub) {
                            setStripeSubscription(stripeSub);
                        }
                        setUser(user);
                    } else {
                        throw new Error('Server not connected');
                    }
                } catch (e) {
                    authService.setLoginInfo(e.message);
                    authService.logout();
                    setIsAuthenticated(false);
                }
            }
            setInitialLoading(false);
        };
        getUserIfAuthenticated();
    }, [initialLoading]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                user,
                setUser,
                isLoading,
                setIsLoading,
                initialLoading,
                setInitialLoading,
                stripeSubscription,
                setStripeSubscription,
            }}
        >
            {initialLoading ? (
                <FullScreenSpinner />
            ) : (
                <Switch>
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Redirect from="/" to="/admin/index" />
                </Switch>
            )}
    </AuthContext.Provider>
    );
};

export default App;
