import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const MONTHS = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
];

const DAYS = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi'
];

const Invoice = ({ invoice }) => {
    const getBadgeText = () => {
        switch (invoice.status) {
            case 'paid': return 'Payée';
            case 'void': return 'Fonds insuffisants';
            default: return 'En attente de paiement';
        }
    }
    
    const date = new Date(invoice.date * 1000);
    const formattedDate = `${DAYS[date.getDay()]} ${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
    const formattedAmount = invoice.amount / 100;
    const badgeClass = invoice.status === 'paid' ? 'bg-success' : 'bg-danger';
    const badgeText = getBadgeText()

    return (
        <tr>
            <td>
                <Badge color="" className="badge-dot mr-4">
                    <i className={badgeClass} /> {badgeText}
                </Badge>
            </td>
            <td>{formattedAmount}&euro;</td>
            <td>{formattedDate}</td>
            <td>
                <a className="btn btn-primary" download href={invoice.pdf}>
                    Télécharger
                </a>
            </td>

        </tr>
    )
}

Invoice.propTypes = {
    invoice: PropTypes.object.isRequired
}

export default Invoice;