import React from 'react';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const CONTINUE_TEXT = 'Continuer';

const InformationModal = ({ isOpen, information, color, onContinue }) => {
    const handleClick = () => {
        if (onContinue) {
            onContinue();
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <Col>
                    <Row className="justify-content-center m-2">
                        <h2 className="text-center">{information}</h2>
                    </Row>
                    <Row className="justify-content-center m-2">
                        <Button color={color} onClick={() => handleClick()}>{CONTINUE_TEXT}</Button>
                    </Row>
                </Col>
            </ModalBody>
        </Modal>
    )
}

InformationModal.propTypes = {
    information: PropTypes.string.isRequired,
    color: PropTypes.string,
    onContinue: PropTypes.func
}

export default InformationModal;