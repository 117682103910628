/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useError from 'hooks/useError';

import pushSlack from 'services/functions/webhooks';
import AuthService from 'services/AuthService';
import AuthContext from 'contexts/AuthContext';
import InformationModal from 'components/Modal/InformationModal';

import { SLACK_FULL } from 'config';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner,
} from 'reactstrap';

const Login = () => {
    const authService = new AuthService();

    const context = useContext(AuthContext);
    const history = useHistory();
    const [passForgotten, setPassForgotten] = useState(false);
    const [hasError, errorMessage, setError] = useError();
    const [showInfo, setShowInfo] = useState(false);
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (value, input) => {
        setCredentials({
            ...credentials,
            [input]: value,
        });
    };

    useEffect(() => {
        if (context.isLoading) {
            context.setIsLoading(false);
        }

        if (authService.isAuthenticated()) {
            history.push('/');
        } else {
            if (authService.isTokenExpired()) {
                setError(true, authService.getLoginInfo());
            } else {
                const info = authService.getLoginInfo();
                if (info) {
                    setError(true, info);
                }
            }
        }
    }, []);

    useEffect(() => {
        setError(false);
    }, [passForgotten]);

    const handleClick = () => {
        if (!passForgotten) {
            login()
        } else {
            sendNewPassForm();
        }
    }

    const login = () => {
        if (context.isLoading) {
            return;
        }

        context.setIsLoading(true);
        authService
            .authenticate(credentials.email, credentials.password)
            .then((tokenInfo) => {
                setError(false);
                pushSlack(SLACK_FULL, { 
                    type: 'TEXT',
                    content: [`${credentials.email}: Connexion`]
                });
                context.setIsAuthenticated(true);
                context.setInitialLoading(true);
            })
            .catch((err) => {
                setError(true, err.message);
                context.setIsLoading(false);
            });
    };

    const sendNewPassForm = () => {
        if (context.isLoading) {
            return;
        }

        context.setIsLoading(true);
        authService
            .sendForgottenPassEmail(credentials.email)
            .then(response => {
                setShowInfo(true);
            })
            .catch (err => {
                setError(true, 'Cet email n\'éxiste pas');
            })
            .finally(() => {
                context.setIsLoading(false);
            });
    }

    const togglePassForgotten = () => {
        setPassForgotten(prevPassForgotten => {
            return !prevPassForgotten;
        })
    }

    const getButtonText = () => {
        if (context.isLoading) {
            return <Spinner size="sm" color="white" />
        } else {
            if (passForgotten) {
                return 'Envoyer';
            } else {
                return 'Se connecter';
            }
        }
    }

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-3">
                        <div className="text-muted text-center mt-2 mb-1">
                            <h2 className="text-primary">{passForgotten ? 'Mot de passe oublié' : 'Connexion'}</h2>
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        {hasError && <p className="text-center text-danger font-weight-bold">{errorMessage}</p>}
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        onChange={(event) => handleInputChange(event.currentTarget.value, 'email')}
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        value={credentials.email}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {!passForgotten && (
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            onChange={(event) => handleInputChange(event.currentTarget.value, 'password')}
                                            placeholder="mot de passe"
                                            type="password"
                                            autoComplete="new-password"
                                            value={credentials.password}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            )}
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="button" onClick={() => handleClick()}>
                                    {getButtonText()}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3 justify-content-center">
                    <Col xs="6 text-center">
                        <a className="btn-light" onClick={(e) => togglePassForgotten()}>
                            <h3 className="text-white">{passForgotten ? 'Annuler' : 'Mot de passe oublié ?'}</h3>
                        </a>
                    </Col>
                </Row>
            </Col>
            <InformationModal isOpen={showInfo} information="Un email vous a été envoyé" color="primary" onContinue={() => setShowInfo(false)} />
        </>
    );
};

export default Login;
