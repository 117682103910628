import { useState } from 'react';

const useError = () => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const setError = (error, message = '') => {
        if (error || hasError) {
            setHasError(error);
            setErrorMessage(message);
        }
    }

    return [hasError, errorMessage, setError];
}

export default useError;