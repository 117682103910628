import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useError from 'hooks/useError';

import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col, Spinner } from 'reactstrap';

import AuthContext from 'contexts/AuthContext';
import AuthService from 'services/AuthService';

const ResetPassword = ({ match }) => {
    const authService = new AuthService();
    const context = useContext(AuthContext);
    const history = useHistory();
    const [hasError, errorMessage, setError] = useError();
    const [newCredentials, setNewCredentials] = useState({
        email: '',
        newPass: '',
        newPassConfirmation: ''
    });

    const handleInputChange = (value, field) => {
        setNewCredentials({
            ...newCredentials,
            [field]: value
        });
    }

    const resetPassword = () => {
        const token = match.params.token;
        if (newCredentials.newPass === newCredentials.newPassConfirmation) {
            context.setIsLoading(true);
            authService.resetPassword(newCredentials.email, newCredentials.newPass, token)
                .then(response => {
                    history.push('/auth/login');
                })
                .catch(e => {
                    setError(true, e.message);
                    context.setIsLoading(false);
                });
        } else {
            setError(true, 'Les mots de passe ne correspondent pas.');
        }
    }

    return (
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 mb-1">
                        <h2 className="text-primary">Changer de mot de passe</h2>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                    {hasError && <p className="text-center text-danger font-weight-bold">{errorMessage}</p>}
                    <Form role="form">
                        <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    onChange={(event) => handleInputChange(event.currentTarget.value, 'email')}
                                    placeholder="Email"
                                    type="email"
                                    autoComplete="new-email"
                                    value={newCredentials.email}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    onChange={(event) => handleInputChange(event.currentTarget.value, 'newPass')}
                                    placeholder="Nouveau mot de passe"
                                    type="password"
                                    autoComplete="new-password"
                                    value={newCredentials.newPass}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    onChange={(event) => handleInputChange(event.currentTarget.value, 'newPassConfirmation')}
                                    placeholder="Confirmation nouveau mot de passe"
                                    type="password"
                                    autoComplete="new-password-conf"
                                    value={newCredentials.newPassConfirmation}
                                />
                            </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                            <Button className="my-4" color="primary" type="button" onClick={() => resetPassword()}>
                                {!context.isLoading ? 'Changer de mot de passe' : <Spinner size="sm" color="white" />}
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Col>
    );
};

export default ResetPassword;
