import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Row, Col, Spinner} from 'reactstrap';

const LoadingModal = ({ isOpen }) => {
    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <Col>
                    <Row className="justify-content-center m-2">
                        <Spinner color="primary" />
                    </Row>
                </Col>
            </ModalBody>
        </Modal>
    )
}

LoadingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired
}

export default LoadingModal;