export default function pushSlack(target, content)
{
	let payload = {
		blocks: [],
	}
	if (!content || !content.content)
	{
		console.error("pushSlack(): no content found. Cancelling push.")
		return null
	}

	if ((!content.type || content.type === 'TEXT'))
	{
		payload = {
			text: ""
		}
		content.content.forEach(e => {
			payload.text = payload.text + e + "\n"
		})
	}

	if (content.type === 'SECTIONS')
	{
		let sections = []

		content.content.forEach(eb => {
			let section = {
				type: "section",
				fields: []
			}
			eb.forEach(es => {
				section.fields.push({
					type: "mrkdwn",
					text: es
				})
			})
			sections.push(section)
		})
		payload.blocks = sections
	}

	if (content.type === 'SECTIONS' && content.context)
	{
		let context = {
			type: "context",
			elements: []
		}
		if (typeof content.context === 'object')
		{
			content.context.forEach(e => {
				context.elements.push({
					type: "mrkdwn",
					text: e
				})
			});
		}
		else
		{
			context.elements.push({
				type: "mrkdwn",
				text: content.context
			})
		}
		payload.blocks.push(context)
	}
	payload = JSON.stringify(payload)
    try {
      fetch(target, { method: 'post', body:  payload})
    } catch ({response}){
      console.error("pushSlack(): ", response)
    }
}